import Header from "./Header";
import HeroSection from "./Hero";
const Home=()=>{




    return(<>
        
       <Header/>
<HeroSection/>
        </>);
}


export default Home;